/////////////////////////////////////////////
// DEFAULT PROPS FOR COMPONENTS
/////////////////////////////////////////////
export { DEFAULT_PROPS } from './default-props';

/////////////////////////////////////////////
// COLOR CONFIGURATION
/////////////////////////////////////////////
export const BG_COLOR = {
  primary: '#002B5C',
  secondary: '#FFFFFF',
  alternative: '#003674',
  gradient: '#785731',
  footer: '#003674',
};

export const TEXT_COLOR = {
  primary: '#fff',
  secondary: '#002B5C',
};

export const NAVIGATION_COLOR = {
  base: '#fff',
  icon: '#000',
};

export const BORDER_COLOR = {
  frame: '#fff',
};

export const BUTTON_COLOR = {
  primary: '#000',
};
